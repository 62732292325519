<!-- 
 * @description: 
 * @fileName: HomeView.vue 
 * @author: mengfei.shen 
 * @date: 2023-04-20 13:51:41
 * @version: V1.0.0 
!-->
<template>
  <div class="container">
    <div style="display: flex; position: relative">
      <img src="@/assets/banner3.jpg" alt="" class="headerimg" />
      <div :class="substatus ? ' errsubscribe' : 'subscribe'" @click="subscribe">
        <span class="iconfont icon-lingdang" id="lingdang"></span>
        <span>{{ substatus ? '已订阅' : '政策订阅' }}</span>
      </div>
    </div>
    <van-tabs v-model="active" @change="changingover">
      <van-tab name="1" title="政策公开"></van-tab>
      <van-tab name="0" title="政策解读"></van-tab>
    </van-tabs>
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <van-cell v-for="item in list" :key="item.id" :title="item.title" :label="item.createTime + '  阅读:' + item.readNum" @click="hrefdetiel(item)" />
    </van-list>

    <van-dialog v-model="show" width="95%" :showCancelButton="true" :confirmButtonText="substatus ? '取消订阅' : '确认'" :showConfirmButton="true" @confirm="subcheckform">
      <img src="@/assets/dy.png" width="100%" alt="" />
      <div class="checkoutbox">
        <div v-if="!substatus">
          <p>大兴企业圈政策订阅功能已上线！企业可点击订阅，我们将不定期推送政策信息。</p>
          <p>若订阅后不满意可随时取消订阅，欢迎体验！</p>
        </div>
        <h2 v-if="!substatus">请选择您感兴趣的政策</h2>
        <h2 v-if="substatus">您当前已订阅的政策</h2>
        <p v-if="substatus">如需更改政策订阅的类型，请取消订阅再次重新订阅即可。</p>
        <van-checkbox-group v-model="result" direction="horizontal">
          <van-checkbox :name="53">资金支持</van-checkbox>
          <van-checkbox :name="54">资质认定</van-checkbox>
          <van-checkbox :name="55">减税降费</van-checkbox>
          <van-checkbox :name="56">人才服务</van-checkbox>
          <van-checkbox :name="57">知识产权</van-checkbox>
          <van-checkbox :name="58">投融资</van-checkbox>
          <van-checkbox :name="59">产业政策</van-checkbox>
          <van-checkbox :name="60">商贸服务</van-checkbox>
          <van-checkbox :name="61">科技创新</van-checkbox>
          <van-checkbox :name="62">其他</van-checkbox>
        </van-checkbox-group>
      </div>
      <!-- <van-button type="danger" size="large" @click="subcheckform">确定订阅</van-button> -->
    </van-dialog>
  </div>
</template>

<script>
import api from '@/api/policy.js'
import lodash from 'lodash'
export default {
  data() {
    return {
      active: '1',
      list: [],
      loading: false,
      finished: false,

      pageinfo: {
        pageNum: 0,
        pageSize: 10,
        policyType: 0
      },
      show: false,
      result: [],
      substatus: true
    }
  },
  // 生命周期 - 创建完成（访问当前this实例）
  created() {
    if (this.$route.query.type == 1) {
      this.show = true
    }
  },
  // 生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    setTimeout(() => {
      this.getSubscribe()
    }, 1000)
  },
  // 方法集合
  methods: {
    //提交
    subcheckform() {
      console.log(this.result)
      if (this.substatus) this.result = []
      const data = {
        tagId: this.result,
        userId: this.$store.state.userid
      }
      api.addSubscribe(data).then(res => {
        console.log(res)
        this.show = false
        this.getSubscribe()
      })
    },
    subscribe() {
      this.show = true
      // if (!this.substatus) {

      // } else {
      //   this.result = []
      //   Toast.success('您已成功取消订阅！')
      //   this.subcheckform()
      // }
    },
    getSubscribe() {
      const data = {
        userId: this.$store.state.userid
      }
      console.log(this.$store.state.userid)
      api.getSubscribe(data).then(res => {
        console.log(res)
        if (res.tdata.tagId.length > 0) {
          this.substatus = true
          this.result = res.tdata.tagId
        } else {
          this.substatus = false
        }
      })
    },
    getlist() {
      api.getlist(this.pageinfo).then(res => {
        this.list = [...this.list, ...res.tdata.list]
        this.total = res.tdata.total

        this.loading = false
        console.log(this.list.length >= this.total)
        if (this.list.length >= this.total) {
          // eslint-disable-next-line no-debugger
          this.finished = true
        }
      })
    },
    onLoad() {
      this.pageinfo.pageNum++
      if (this.refreshing) {
        this.list = []
        this.refreshing = false
      }
      this.getlist()
    },
    onRefresh() {
      // 清空列表数据

      this.pageinfo.pageNum = 0
      this.finished = false
      this.list = []
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.onLoad()
    },
    changingover: lodash.debounce(function () {
      //具体方法
      this.list = []
      this.pageinfo.policyType = this.active
      // console.log(lodash.debounce());
      sessionStorage.setItem('activetab', this.active)
      // lodash.debounce(this.onRefresh())
      this.onRefresh()
    }, 500),
    hrefdetiel(row) {
      if (row.policyUrl) {
        api.policyDetail({ id: row.id }).then(() => {
          window.location.href = row.policyUrl
        })
      } else {
        this.$router.push({
          path: '/about',
          query: {
            id: row.id
          }
        })
      }
    }
  }
}
</script>
<style scoped lang="scss">
::v-deep .van-cell {
  padding: 15px 20px;
  font-size: 16px;
}
/* @import url(); 引入css类 */
.container {
  width: 100%;
  background-color: #f9f9f9;
}
.headerimg {
  width: 100%;
}
::v-deep.van-cell__label {
  padding-top: 10px;
}
.footerimg {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;
}
::v-deep .van-list {
  padding-bottom: 60px;
}
.subscribe {
  position: absolute;
  right: 15px;
  bottom: 15px;
  font-size: 18px;
  background-color: #4b60a2;
  color: #fff;
  border-radius: 10px;

  padding: 7px 10px;
  > .iconfont {
    font-size: 22px;
  }
}
#lingdang {
  display: inline-block;
  animation-name: zoom-in-out; /* 引用之前定义好的动画序列 */
  animation-duration: 1s; /* 动画持续时间为3秒 */
  animation-iteration-count: infinite; /* 无限次数地重复执行动画 */
}
@keyframes zoom-in-out {
  0% {
    transform: scale(1);
  } /* 初始状态 */
  50% {
    transform: scale(1.2);
  } /* 放大到原来的1.2倍 */
  100% {
    transform: scale(1);
  } /* 还原至初始状态 */
}
.errsubscribe {
  position: absolute;
  right: 15px;
  bottom: 15px;
  font-size: 18px;
  background-color: #adadad;
  color: #fff;
  border-radius: 10px;
  padding: 7px 10px;
  > .iconfont {
    font-size: 22px;
    animation-name: zoom-in-out; /* 引用之前定义好的动画序列 */
    animation-duration: 3s; /* 动画持续时间为3秒 */
    animation-iteration-count: infinite; /* 无限次数地重复执行动画 */
  }
}
.checkoutbox {
  padding: 10px 30px;
}
.van-checkbox--horizontal {
  margin-bottom: 10px;
}
</style>
